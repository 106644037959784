import { Icon } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Heading, LinkButton, Picture } from '@troon/ui';
import { Show } from 'solid-js';
import type { ComponentProps, JSX } from 'solid-js';

type Props = {
	background?: string;
	image: string;
	title: string;
	level?: ComponentProps<typeof Heading>['as'];
	content: JSX.Element;
	url?: string;
	linkText?: JSX.Element;
};

export function Benefit(props: Props) {
	return (
		<div class={twJoin('relative overflow-hidden rounded border border-neutral', props.url && 'group/benefit')}>
			<Show when={props.background}>
				{(bg) => (
					<div class="-z-0 -mb-20 hidden w-full overflow-hidden md:block">
						<Picture
							src={bg()}
							alt=""
							sizes={[[400, 150]]}
							class="w-full bg-center object-contain transition-transform group-hover/benefit:scale-105"
							loading="lazy"
						/>
					</div>
				)}
			</Show>
			<div class={twJoin('flex flex-col gap-4 p-4 md:p-6')}>
				<div class="z-0 aspect-square w-fit rounded border border-neutral bg-white p-2">
					<Picture
						src={props.image}
						sizes={[[100, 100]]}
						class="aspect-square w-24"
						mode="contain"
						crop="center"
						alt={props.title}
						loading="lazy"
					/>
				</div>
				<div class="z-0 flex flex-col gap-1">
					<Heading as={props.level ?? 'h4'}>{props.title}</Heading>
				</div>
				<p class="text-neutral-900">{props.content}</p>
				<Show when={props.url}>
					<LinkButton href={props.url} class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0">
						{props.linkText}
						<Icon name="chevron-right" />
					</LinkButton>
				</Show>
			</div>
		</div>
	);
}
